@import url('https://fonts.googleapis.com/css2?family=Oswald&family=Source+Sans+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');


/* 
font-family: 'Outfit', sans-serif;
        font-weight: 600px; */

html, body, #root {
    height: 100vh;
    width: 100vw;
    
}

.active-link {
    text-decoration: underline;
    text-decoration-color: white;
}

.cover {
    height: 500px;
}

.spread {
    width: 800px;
    display: flex;
    justify-content: center;
}

.navbar-nav .nav-link {
    color: white !important;
    text-shadow: 2px 2px 100px rgba(0, 0, 0, 50);
}

.navbar-width {
    max-width: 90vw;
}

.img-square {
    max-width: 400px;
}

.port-body-text {
    min-width: 400px;
}


.social-links:hover {
    filter: brightness(80%);
  }

.social-links-footer:hover {
    filter: brightness(80%);
  }

.contact-input form label {
    font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    color: white;
    display: block;
    text-align: left;
    margin-bottom: 5px;
  }
  
  .contact-input form input[type="text"],
  .contact-input form input[type="email"],
  .contact-input form textarea {
   font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    padding: 5px;
    width: 100%;
  }
  
  .contact-input form input[type="submit"] {
   font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    background-color: transparent;
    border: none;
    color: white;
    padding: 10px;
    margin-top: 10px;
    display: block;
    margin: 0 auto;
    border: .5px solid;
    border-color: white;
    border-radius: 5px;
  }

  .contact-input form input[type="submit"]:hover {
    background-color: transparent;
  }


.icons {
    width: 60px;
    margin: 25px;
    padding-top: 10px;
}

.web-icons {
    width: 35px;
    margin: 10px;
}


  .web-icons:hover {
    opacity: 0.5;
    transition: opacity 0.5s ease;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .position-relative:hover .overlay {
    opacity: 1;
  }


  
  .col-lg-4:hover .overlay,
  .col-md-6:hover .overlay {
    opacity: 1;
  }
  

  
  .card-port:hover .overlay {
    opacity: 1;
  }


  .icon-link {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }


.navbar-toggler {
    border: none;
    width: 50px;
}

.row {
    max-width: 1200px;
}

.card-port {
    width: 325px;
    position: relative;
}


.contact-input {
    max-width: 400px;
}

.contact-box {
    max-width: 400px;
}

.contact-title {
    font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: 700;
    font-size: 60px;
    text-align: left;
}
.contact-subheading {
    font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: 400;
    font-size: 30px;
    text-align: left;
}

.contact-body {
    font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: 200;
    font-size: 15px;
    text-align: left;
}

.text-title {
    font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: 700;
    font-size: 80px;
}

.text-subnav {
    font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    color: #88898C;
}

.text-subnav:hover {
    color: white;
    text-decoration: none;
}
.text-subnav:focus {
    color: white;
    text-decoration: none;
}
.text-subnav:active {
  color: white;
  text-decoration: none;
}

.text-subnav:clicked {
    color: grey;
    text-decoration: none;
    border-bottom: 2px solid white;
    text-align: center;
}

.about-image {
    position: relative;
    height: 500px;
    width: 500px;
    object-fit: scale-down;
    max-width: 100%;
  }
  

/* If the screen size is 1200px wide or more */
@media (min-width: 1200px) {

    .img-square {
        max-width: 400px;
    }

    .blog-container {
        padding: 0px 100px 0px 100px;
    }

    .text-title {
      font-size: 90px;
    }

    .text-subnav {
        font-size: 15px;
    }
    .about-header {
       font-family: 'Outfit', sans-serif;
        font-weight: 600px;
        font-weight: 700;
        font-size: 50px;
        text-align: left;
        line-height: 40px;
    }
    .about-subheader {
       font-family: 'Outfit', sans-serif;
        font-weight: 600px;
        color: white;
        font-size: 25px;
        text-align: left;
        font-weight: 200;
        text-align: left;
    }
    .about-subheader-2 {
       font-family: 'Outfit', sans-serif;
        font-weight: 600px;
        color: #88898C;
        font-size: 20px;
        text-align: left;
        font-weight: 200;
        text-align: left;
    }
    .about-body {
       font-family: 'Outfit', sans-serif;
        font-weight: 600px;
        font-weight: 400;
        text-align: left;
        line-height: 30px;
        font-size: 15px;
        text-align: left;
    }
    .text-header {
        font-family: 'Outfit', sans-serif;
        font-weight: 600;
        font-size: 90px;
        text-align: center;
        line-height: 90px;
        text-align: center;
        letter-spacing: 4px;
    }

    .text-second {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-size: 40px;
        color: white;
    }

  }
  /* If the screen size is smaller than 1200px */
  @media (max-width: 1199px) {
    .icons {
        width: 40px;
        margin: 25px;
        padding-top: 10px;
    }
    .text-title {
      font-size: 60px;
    }
    .text-subnav {
        font-size: 17px;
    }
    .about-header {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-weight: 700;
        font-size: 40px;
        text-align: left;
        line-height: 40px;
    }
    .about-subheader {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        color: white;
        font-size: 15px;
        font-weight: 200;
        text-align: left;
    }
    .about-subheader-2 {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        color: #88898C;
        font-size: 12px;
        font-weight: 200;
        text-align: left;
    }
    .about-body {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-weight: 400;
        line-height: 25px;
        font-size: 12px;
        text-align: left;
    }
    .about-image {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-weight: 400;
        line-height: 30px;
        font-size: 15px;
        object-fit: scale-down;
        max-width: 100%;
    }
    .text-header {
        font-family: 'Outfit', sans-serif;
        font-weight: 600px;
        font-size: 80px;
        text-align: center;
    }
    .text-second {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-size: 30px;
        color: white;
    }
}

@media (max-width: 1000px) {
    .social-links {
        display: none;
    }
    .navbar-toggler {
        position: absolute;
        right: 0;
    }
    .text-header {
        font-family: 'Outfit', sans-serif;
        font-weight: 700;
        font-size: 70px;
        text-align: center;
    }
}

  @media (max-width: 800px) {

    .img-square {
        max-width: 300px;
    }
    
    .text-header {
        font-family: 'Outfit', sans-serif;
        font-weight: 600;
        font-size: 70px;
        line-height: 70px;
        text-align: center;
    }
    .text-second {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-size: 20px;
        color: white;
    }
  }

/* If the screen size is smaller than 1200px */
@media (max-width: 600px) {
    .icons {
        width: 60px;
        margin: 25px;
        padding-top: 10px;
    }

    .text-title {
        font-size: 40px;
    }
    .text-subnav {
        font-size: 17px;
    }
    .about-header {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-weight: 700;
        text-align: left;
        line-height: 40px;
    }
    .about-subheader {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        color: white;
        font-size: 20px;
        font-weight: 200;
        text-align: left;
    }
    .about-subheader-2 {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        color: #88898C;
        font-size: 15px;
        font-weight: 200;
        text-align: left;
    }
    .about-body {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-weight: 400;
        line-height: 25px;
        font-size: 12px;
        text-align: left;
    }
    .text-header {
        font-family: 'Outfit', sans-serif;
        font-weight: 600px;
        font-size: calc(20px + 4em);
        overflow: hidden;
        font-size: 70px;
        line-height: 60px;
        text-align: left;
    }
    .text-second {
       font-family: 'Outfit', sans-serif;
    font-weight: 600px;
        font-size: 15px;
        color: white;
    }
}

@media (max-width: 500px) {
    .contact-title {
      font-size: 2rem;
      padding: 1rem 0;
    }

    .contact-input {
      padding: 2rem 1rem;
    }
  }

.grey {
    color:#88898C;
}

.top-nav {
    font-family: 'Hind', sans-serif;
}





.blog-header {
   font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: 500;
}

.blog-sub-header {
   font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: bold;
    text-align: left;
}

.blog-sub-header2 {
   font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: bold;
    text-align: left;
}

.blog-body-q {
   font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    font-weight: 400;
    text-align: left;
    line-height: 35px;
}

.blog-body {
   font-family: 'Outfit', sans-serif;
    font-weight: 600px;
    color: #A4A5A6;
    font-size: 16;
    text-align: left;
    font-weight: 200;
}

.images .hidden {
    display: none;
}